<template>
  <table
    style="font-size: 0; margin-top: 5px;"
    cellspacing="0"
    cellpadding="0"
    border="0"
    role="presentation"
  >
    <tbody>
      <tr>
        <td width="550">
          <span style="display: block; font-size: 10px; color: #888888;">This email and any attachments are confidential and intended solely for the person or entity to whom it is addressed.
            The disclosure of this message is strictly forbidden. If you received this email in error, please notify the sender immediately. <a
              target="_blank"
              href="https://www.lengow.com/gdpr/opt-out/"
              style="font-size: 10px; color: white; text-decoration: none!important"
            ><span style="color: #001049;">Click here</span></a> if you don't want to be contacted by Lengow anymore.
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'Disclaimer'
}
</script>
