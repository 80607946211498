<template>
  <tr v-if="options.calendar.show">
    <td
      width="25"
      valign="top"
      style="vertical-align: middle; line-height: 1; width: 25px;"
    >
      <img
        v-if="options.company.selected === 'Lengow'"
        :src="`/img/icons/calendar_2.png`"
        style="width: 15px; vertical-align: middle;"
        alt="calendar"
      >
      <img
        v-if="options.company.selected === 'Netrivals'"
        :src="`/img/icons/netrivals/calendar.png`"
        style="width: 15px; vertical-align: middle;"
        alt="calendar"
      >
    </td>
    <td
      valign="top"
      style="vertical-align: middle; line-height: 1; font-size: 9pt;"
    >
      <a
        :href="formatLink(options.calendar.link)"
        target="_blank"
        style="text-decoration: none; color: white; vertical-align: middle;"
      ><span style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;">{{ options.calendar.text }}</span></a>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Calendar',

  computed: {
    ...mapState(['options'])
  },
  methods: {
    formatLink (value) {
      return /^https?/.test(value) ? value : `https://${value}`
    }
  }
}
</script>
