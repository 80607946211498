<template>
  <tr>
    <td v-if="socials.installed.length">
      <table
        cellspacing="2"
        cellpadding="0"
        border="0"
        role="presentation"
        style="margin-top: 5px; margin-left: -2px; font-size: 0;"
      >
        <tbody>
          <tr>
            <td
              v-for="item in socials.installed"
              :key="item.name"
              align="center"
              style="width: 15px; height: 15px; padding: 5px 15px 5px 3px; vertical-align: middle;"
            >
              <a
                v-if="item.name === 'Facebook'"
                style="display: grid;"
                target="_blank"
                :href="formatLink(item.link)"
              >
                <img
                  :src="`/img/icons/facebook.png`"
                  alt="facebook"
                >
              </a>
              <a
                v-if="item.name === 'Twitter'"
                style="display: grid;"
                target="_blank"
                :href="formatLink(item.link)"
              >
                <img
                  :src="`/img/icons/x.png`"
                  alt="x"
                >
              </a>
              <a
                v-if="item.name === 'Instagram'"
                style="display: grid;"
                target="_blank"
                :href="formatLink(item.link)"
              >
                <img
                  :src="`/img/icons/instagram.png`"
                  alt="instagram"
                >
              </a>
              <a
                v-if="item.name === 'Linkedin'"
                style="display: grid;"
                target="_blank"
                :href="formatLink(item.link)"
              >
                <img
                  :src="`/img/icons/linkedin.png`"
                  alt="linkedin"
                >
              </a>
              <a
                v-if="item.name === 'WelcomeToTheJungle'"
                style="display: grid;"
                target="_blank"
                :href="formatLink(item.link)"
              >
                <img
                  :src="`/img/icons/ wttj.png`"
                  alt="welcome to the jungle"
                >
              </a>
              <a
                v-if="item.name === 'YouTube'"
                style="display: grid;"
                target="_blank"
                :href="formatLink(item.link)"
              >
                <img
                  :src="`/img/icons/youtube.png`"
                  alt="youtube"
                >
              </a>
              <a
                v-if="item.name === 'Xing'"
                style="display: grid;"
                target="_blank"
                :href="formatLink(item.link)"
              >
                <img
                  :src="`/img/icons/xing.png`"
                  alt="xing"
                >
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Social',

  computed: {
    ...mapState(['options', 'socials'])
  },

  methods: {
    formatLink (value) {
      return /^https?/.test(value) ? value : `https://${value}`
    }
  }
}
</script>
