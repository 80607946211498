import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['basic', 'options', 'socials']),
    isEmpty () {
      return this.basic.fields.every(item => item.value.length === 0)
    },
    mainFields () {
      return this.basic.fields
    },
    showTicket () {
      return this.options.ticket.show
    },
    showCalendar () {
      return this.options.calendar.show
    }
  },
  methods: {
    formatLink (value) {
      return /^https?/.test(value) ? value : `https://${value}`
    }
  }
}
