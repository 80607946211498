<template>
  <tr v-if="mainFields[3].value !== ''">
    <td
      width="25"
      valign="top"
      style="vertical-align: middle; line-height: 1; width: 25px;"
    >
      <span style="vertical-align: middle;">
        <img
          v-if="options.company.selected === 'Lengow'"
          :src="`/img/icons/phone.png`"
          style="width: 15px; vertical-align: middle;"
          alt="phone"
        >
        <img
          v-if="options.company.selected === 'Netrivals'"
          :src="`/img/icons/netrivals/phone.png`"
          style="width: 15px; vertical-align: middle;"
          alt="phone"
        >
      </span>
    </td>
    <td
      valign="top"
      style="vertical-align: middle; line-height: 1; font-size: 9pt;"
    >
      <a
        :href="`tel:${phoneNumber}`"
        target="_blank"
        style="text-decoration: none; color: white; vertical-align: middle;"
      >
        <span
          style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;"
        >
          {{ phoneNumber }}
        </span>
      </a>
    </td>
  </tr>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Phone',
  props: {
    mainFields: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    ...mapState(['options']),
    phoneNumber () {
      let phone = this.mainFields[3].value
      if (!phone || /^([a-z+])/i.test(phone)) {
        return phone
      }

      switch (this.options.office.selected) {
        case 'Nantes':
        case 'Paris':
          return 'FR, +33' + phone.slice(1)
        case 'Barcelona':
          return 'ES, +34' + phone.slice(1)
        default:
          return phone
      }
    }
  }
}
</script>
